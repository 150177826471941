export const getFeeDeggrp = (state = [], action) => {
  switch (action.type) {
    case "GET_DEGGRP_DET":
      return action.payload.data;
    case "CLEAR_DEGGRP_DET":
      return [];
    default:
      return state;
  }
};

export const getFeeCollDet = (state = [], action) => {
  switch (action.type) {
    case "GET_FEE_COLL_DAT":
      return action.payload.data;
    case "CLEAR_FEE_COLL_DAT":
      return [];
    default:
      return state;
  }
};

export const getFeeDegree = (state = [], action) => {
  switch (action.type) {
    case "GET_FEE_DEGREE_DAT":
      return action.payload.data;
    case "CLEAR_FEE_DEGREE_DAT":
      return [];
    default:
      return state;
  }
};

export const getDailyFeeReportDet = (state = [], action) => {
  switch (action.type) {
    case "GET_FEE_REPORT_DAT":
      return action.payload.data;
    case "CLEAR_FEE_REPORT_DAT":
      return [];
    default:
      return state;
  }
};

export const getDetailFeeDat = (state = [], action) => {
  switch (action.type) {
    case "GET_DET_FEE_REPORT":
      return action.payload.data;
    case "CLEAR_DET_FEE_REPORT":
      return [];
    default:
      return state;
  }
};
